import React from "react";
// import sections
import Welcome from "../components/sections/Welcome";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import Testimonial from "../components/sections/Testimonial";
import Contact from "../components/sections/Contact";

export default function Home() {
  return (
    <>
      <Welcome className='illustration-section-01' />
      <FeaturesTiles />
      <FeaturesSplit
        invertMobile
        topDivider
        imageFill
        className='illustration-section-02'
      />
      <Testimonial topDivider />
      {/* <Contact split /> */}
    </>
  );
}
