import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      value: "Please write an essay about your favorite DOM element.",
    };
  }

  handleSubmit = (e) => {
    // console.log("e", e);
    // send to server with e.g. `window.fetch`
  };

  handleEmailChange = () => {
    console.log("value", this.state.email);
  };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "contact section center-content-mobile reveal-from-bottom",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "contact-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider",
      split && "contact-split"
    );
    return (
      <section {...props} className={outerClasses}>
        <div className='container'>
          <div className={innerClasses} style={{ background: "#1d2f3f" }}>
            <div class='contact-form-wrap'>
              <form
                id='demo'
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log("e", e.currentTarget.value);
                }}>
                <div class='contact-form__two'>
                  <div class='contact-input grad'>
                    <div class='contact-inner'>
                      <input
                        name='con_name'
                        type='text'
                        placeholder='First Name *'
                        id='nameFirst'
                        required
                      />
                    </div>
                    <div class='contact-inner'>
                      <input
                        name='con_last'
                        type='text'
                        placeholder='Last Name *'
                        id='nameLast'
                        required
                      />
                    </div>
                  </div>
                  <div class='contact-input'>
                    <div class='contact-inner'>
                      <input
                        name='con_number'
                        type='number'
                        maxLength={10}
                        placeholder='Mobile Number *'
                        id='phone'
                        required
                      />
                    </div>
                    <div class='contact-inner'>
                      <input
                        name='con_email'
                        type='email'
                        placeholder='Email *'
                        id='email'
                        required
                      />
                    </div>
                  </div>
                  <div class='submit-btn'>
                    <button
                      class='button button-primary button-wide-mobile button-sm'
                      data-toggle='modal'
                      data-target='#exampleModal'
                      type='submit'>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Contact.defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

Contact.propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};
export default Contact;
