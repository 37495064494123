import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";

const Logo = ({ className, ...props }) => {
  const classes = classNames("brand", className);

  return (
    <div {...props} className={classes}>
      <Image
        src={require("./../../../assets/images/logo.svg")}
        alt='Open'
        width={50}
        height={50}
      />
    </div>
  );
};

export default Logo;
